<template>
    <div class="card">
        <!-- <div class="card">
            <div class="row">
                <div class="col-md-6">
                    <div class="card-body">
                        <h4 class="card-title">Filtrados Por:</h4>
                        <div class="form-row form-group" role="group">
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="col">
                                        <b-form-checkbox  id="produto-ativo1" name="ativo"
                                            value="-1">
                                            001-Custodia de Empresa
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="card text-center">
            <!-- ola -->
            <!-- <div class="row">
                
                <div class="col-sm-12 col-md-6">
                    <button v-if="isHide" class="btn btn-light" @click="searchInputs()">
                        <span class="fa-sm fa" :class="(hideSearches) ? 'fa-chevron-up' : 'fa-chevron-down'"></span> Busca
                        avançada
                    </button>
                </div>
            </div> -->
            <h1>Listas Contas a Pagar</h1>
        </div>
        <!-- v-if="hideSearches" -->
        <div class="accordion" role="tablist">
            <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.pesquisar variant="light">Como deseja Producar:</b-button>
                    </b-card-header>
                    <b-collapse id="pesquisar" visible accordion="pesquisar" role="tabpanel">
                        <b-card-body>
                            <div class="form-row form-group" role="group">
                                <!-- <div class="col-sm-12 col-md-12" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Tipo de produtos:</label>
                                        <div class="col">
                                            <Multiselect :options="modelos">

                                            </Multiselect>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row form-group" role="group">
                                <!-- <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Tipo de produtos:</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo" ref="cheque_status">Todos
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo" ref="cheque_status">Tipo1
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo" ref="cheque_status">Tipo2
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row form-group" role="group">
                                <!-- <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">De:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Até:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Modelo</label>
                                        <div class="col">
                                            <Multiselect v-model="modelo" :options="modelos">
                                            </Multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Status</label>
                                        <div class="col">
                                            <Multiselect v-model="status" :options="modelos">
                                            </Multiselect>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Pagos:</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                                                001-Custodia de Empresa
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Não Pagos:</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                                                002-Custodia de Empresa
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Todos:</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                                                002-Custodia de Empresa
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card-body">
                                        <h4 class="card-title">Vencimento</h4>
                                        <p class="card-title-desc">Use esses campos para filtrar os registros</p>
                                        <form class="needs-validation" name="search">
                                            <div class="form-group">
                                                <label for="searchCompras-fromData">De:</label>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <input id="searchCompras-fromData" type="date" class="form-control"
                                                            placeholder="dd/mm/aaaa hh:mm" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input id="searchCompras-toData" type="date" class="form-control"
                                                            placeholder="dd/mm/aaaa hh:mm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card-body">
                                        <h4 class="card-title">Pagamento em :</h4>
                                        <p class="card-title-desc">Use esses campos para filtrar os registros</p>
                                        <form class="needs-validation" name="search">
                                            <div class="form-group">
                                                <label for="searchCompras-fromData">De:</label>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <input id="searchCompras-fromData" type="date" class="form-control"
                                                            placeholder="dd/mm/aaaa hh:mm" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input id="searchCompras-toData" type="date" class="form-control"
                                                            placeholder="dd/mm/aaaa hh:mm" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-4">
                                    <div class="form-group">
                                        <label for="tipo_id" class="col-form-label">Selecionar fornecedor</label>
                                        <div class="col" id="fornecedor_id">
                                            <multiselect :options="fornecedores" label="pes_fantasia">

                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <div class="form-group">
                                        <label for="tipo_id" class="col-form-label">Selecionar Centro de Custo</label>
                                        <div class="col" id="fornecedor_id">
                                            <multiselect :options="centtros" label="identificacao">

                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <div class="form-group">
                                        <label for="tipo_id" class="col-form-label">Selecionar Centro de Resultado</label>
                                        <div class="col" id="fornecedor_id">
                                            <multiselect :options="resultados" label="identificacao">

                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </form>
        </div>
        <div class="card-body ">
            <div class="row">
                <div class="col-md-2 mt-2 ml-4">
                    <label for="">&nbsp;</label>
                    <div class="card">
                        <button class="btn btn-light" @click="gerar_pdf()">Visualizar</button>
                    </div>
                </div>
                <div class="col-md-2 mt-4 ml-4 ">
                    <div class="card">
                        <label for="">Completo</label>
                        <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                            001-Custodia de Empresa
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="col-md-2 mt-4 ml-4">
                    <div class="card">
                        <label for="">Simples</label>
                        <b-form-checkbox id="produto-ativo1" name="ativo" value="-1">
                            001-Custodia de Empresa
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import {http} from "../../../../../helpers/easyindustriaapi/config";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
    data() {
        return {
            nome: 'ola',
            hideSearches: false,
            centtros: [
            ],
            fornecedores: [
               
            ],
            resultados: [
               
            ],
            historicos:[

            ]
        }
    },
    components: { Multiselect },
    computed: {
        isHide() {
            return (!this.inserindo && !this.editando);
        },
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    },
    mounted(){
       this.get_fornecedores();
       this.get_centros();
       this.get_centros_resultados();
       this.get_historicos()
    },
    methods: {
        // ok
        makeToast(variant = null, message = 'Error generic') {
            this.counter++;
            this.$bvToast.toast(message, {
                title: `Notificação`,
                toaster: 'b-toaster-bottom-right',
                variant: variant,
                solid: true,
                appendToast: true
            });
        },
        // geters para preencher os vetotes do multiselct 
        async get_fornecedores() {
            try {
                await http.get("/pessoa?empresa_id=" + this.currentEmpresa.id)
                    .then(res => {
                        this.fornecedores = res.data ? res.data : null;
                       // console.log(this.fornecedores)
                    })

            } catch (error) {
                // this.offLoader();
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }
            }
        },
        async get_centros() {
            try {
                await http.get("/centrocusto?empresa_id=" + this.currentEmpresa.id)
                    .then(res => {
                        this.centtros = res.data ? res.data : null;
                       // console.log(this.centtros)
                    })

            } catch (error) {
                // this.offLoader();
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }
            }
        },
        async get_centros_resultados() {
            try {
                await http.get("/centroresultado?empresa_id=" + this.currentEmpresa.id)
                    .then(res => {
                        this.resultados = res.data ? res.data : null;
                        console.log(this.resultados)
                    })

            } catch (error) {
                // this.offLoader();
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }
            }
        },
        async get_historicos() {
            try {
                await http.get("/historicopadrao?empresa_id=" + this.currentEmpresa.id)
                    .then(res => {
                        this.historicos = res.data ? res.data : null;
                        console.log(this.historicos)
                    })

            } catch (error) {
                // this.offLoader();
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }
            }
        },
        get_centro_resultados() {

        },
        get_centro_custo() {

        },
        // -----------------------------------------
        doPost() {

        },
        gerar_pdf() {
            this.makeToast('sucess', 'Gerando PDF');
            let tamanho = 1
            const doc = new jsPDF();
            const titulo = "Lista de Contas a Pagar";
            const header = ["Nome", "Razão", "CNPJ"];
            const footer = ["Total Geral",`${tamanho}`, ""];
            // Adicionar o header e o foote
            doc.text(titulo, 80, 10,);
            // Array para armazenar as linhas da tabela
            const linhasDaTabela = [];
            // Preencher o array com linhas para cada objeto
            this.fornecedores.forEach(function (objeto) {
                const linha = [objeto.pes_apelido, objeto.pes_cidade, objeto.pes_cpfcnpj];
                linhasDaTabela.push(linha);
            });
            doc.autoTable({
                head: [header],
                body: linhasDaTabela,
                foot: [footer],
            });
            // this.clientes.forEach(function (dado) {
            //     doc.autoTable({
            //         body: [dado],
            //     });
            // });
            // Salvar o documento
            doc.save("relatorio.pdf");
        },
        searchInputs() {
            this.hideSearches = !this.hideSearches;
        },
    }

}
</script>

<style></style>